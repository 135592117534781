@use "@scss/variables" as *;

$this: ".Footer";

#{$this} {
  $Logo: ".Logo";
  background-color: $color-green;
  color: $color-beige;

  #{$Logo} img {
    height: 35px;
  }

  &__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0;

    @media (min-width: $breakpoint-medium) {
      flex-direction: row;
    }
  }

  &__Logo {
    margin-bottom: 10px;

    @media (min-width: $breakpoint-medium) {
      margin-bottom: 0;
    }
  }

  &__Copyright {
    margin-bottom: 0;
  }

}
