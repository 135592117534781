@use "@scss/variables" as *;

$this: ".FeatureCard";

#{$this} {
  &__TitleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__Icon {
    margin-right: 12px;
    font-size: 30px;
  }

  &__Title {
    font-size: 19px;

    @media (min-width: $breakpoint-large) {
      font-size: 20px;
    }
  }

  &__Description {
    margin-bottom: 0;
  }
}
