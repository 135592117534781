@use "@scss/fonts" as *;
@use "@scss/variables" as *;

$this: ".Meonbridge";

html {
  font-size: 15px;

  @media (min-width: $breakpoint-medium) {
    font-size: 16px;
  }
}

body {
  background-color: $color-beige;
  margin: 0;
}

h1, h2, h3, h4, h5, h6, a {
  font-family: $font-primary;
  color: inherit;
  margin: 0;
  line-height: 1.5;
}

p {
  font-family: $font-secondary;
  color: inherit;
  margin: 0 0 15px 0;
  line-height: 1.5;
}

ul {
  padding-left: 15px;
}

li {
  font-family: $font-secondary;
  color: inherit;
  margin: 0 0 5px 0;
  line-height: 1.5;
}

a {
  color: inherit;
}

b {
  font-weight: 600;
}

#{$this} {
  $ServiceCard: ".ServiceCard";
  position: relative;

  &__Header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &__HeroSection {
    position: relative;

    &:before {
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url("../_img/bridge-small-25.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;

      @media (min-width: $breakpoint-medium) {
        background-image: url("../_img/bridge-medium-25.jpg");
      }

      @media (min-width: $breakpoint-large) {
        background-image: url("../_img/bridge-large-25.jpg");
      }

      @media (min-width: $breakpoint-xlarge) {
        background-image: url("../_img/bridge-xlarge-25.jpg");
      }

      @media (min-width: $breakpoint-xxlarge) {
        background-image: url("../_img/bridge-xxlarge-25.jpg");
      }

    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      background-color: $color-green;
    }
  }

  &__ServicesSummaryContainer {
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $breakpoint-medium) {
      gap: 70px;
    }

    @media (min-width: $breakpoint-large) {
      flex-direction: row;
      gap: initial;
      justify-content: space-evenly;
    }

  }

  &__ServicesContainer {
    $gap: 140px;
    $gap-medium: 200px;
    display: flex;
    justify-content: space-between;
    gap: $gap;
    flex-direction: column;
    align-items: center;

    @media (min-width: $breakpoint-medium) {
      gap: $gap-medium;
    }

    @media (min-width: $breakpoint-large) {
      align-items: flex-start;
    }

    #{$ServiceCard} {
      position: relative;

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          bottom: -$gap / 2;
          left: 33%;
          width: 34%;
          height: 2px;
          background-color: $color-navy;
          border-radius: 6px;

          @media (min-width: $breakpoint-medium) {
            //bottom: -$gap-medium / 2;
            left: 40%;
            width: 20%;
          }
        }
      }


    }
  }

  &__ProfileCard {
    margin-bottom: 35px;

    @media (min-width: $breakpoint-medium) {
      margin-bottom: 100px;
    }
  }

  &__ContactContainer {
    display: flex;
    flex-direction: column;
    gap: 50px;

    @media (min-width: $breakpoint-medium) {
      align-items: center;
    }

    @media (min-width: $breakpoint-large) {
      flex-direction: row;
      flex-flow: row-reverse;
      gap: 150px;
    }

    &Text {
      display: flex;
      align-items: center;
      flex: 1;
      @media (min-width: $breakpoint-medium) {
        font-size: 20px;
      }

      p {
        margin: 0;
      }
    }

    &Details {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

  }
}
