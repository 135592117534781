@use "@scss/variables" as *;

$this: ".Hero";

#{$this} {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  &__Title {
    font-size: clamp(2rem, 6vw, 4rem);
    text-align: center;
    margin: 0;
    color: $color-beige;
  }
}
