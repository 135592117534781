@use "@scss/variables" as *;

$this: ".NavLink";

#{$this} {
  padding: 12px 24px;
  color: $color-beige;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}
