// Variables for font paths (adjust as needed)
$font-path: '/font/Outfit';

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Thin.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-ExtraLight.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url("#{$font-path}/Outfit-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}
