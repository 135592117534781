@use "@scss/variables" as *;

$this: ".Navigation";
$Button: ".Button";

#{$this} {
  $mobileBreakpoint: $breakpoint-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  gap: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $color-green;
  z-index: 1;
  font-size: 28px;
  opacity: 0;
  transition-property: opacity, transform;
  transition: 0.2s ease;
  pointer-events: none;
  transform: translateY(20%) scale(0.98);

  @media (min-width: $mobileBreakpoint) {
    position: initial;
    background-color: transparent;
    height: auto;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 20px;
  }

  #{$Button} {
    margin: 12px 0;
  }

  &__CloseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
    top: 0;
    right: 0;
    position: absolute;
    margin-top: 24px;
    margin-right: 12px;

    @media (min-width: $mobileBreakpoint) {
      display: none;
    }
  }

  &--Visible {
    opacity: 1;
    pointer-events: initial;
    transform: translateY(0) scale(1);
  }
}

#{$this}MenuIcon {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-right: -8px;
  cursor: pointer;
  font-size: 24px;
}
