@use "@scss/variables" as *;

$this: ".ServiceSummaryCard";

#{$this} {

  @media (min-width: $breakpoint-large) {
    width: 370px;
  }

  &__Container {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-navy;
    border-radius: 6px;

    @media (min-width: $breakpoint-medium) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media (min-width: $breakpoint-large) {
      flex-direction: column;
      justify-content: space-evenly;
    }

  }

  &__Image {
    height: 370px;
    border-radius: 6px 6px 0 0;
    position: relative;
    overflow: hidden;
    width: 100%;

    @media (min-width: $breakpoint-medium) {
      width: 300px;
      border-radius: 6px 0 0 6px;
      height: auto;
    }

    @media (min-width: $breakpoint-large) {
      height: 370px;
      border-radius: 6px 6px 0 0;
      width: 100%;
    }


    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: $color-green;
      opacity: 0.6;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    @media (min-width: $breakpoint-medium) {
      justify-content: flex-start;
      flex: 1;
    }
  }

  &__Title {
    font-size: 24px;
    margin: 0 0 20px 0;
    color: $color-navy;
    margin-bottom: 15px;
  }

  &__Description {
    color: $color-navy;
  }
}
