// Variables for font paths (adjust as needed)
$font-path: '/font/Montserrat';

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Thin.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-ExtraLight.ttf") format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-ExtraBold.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("#{$font-path}/Montserrat-Black.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}
