@use "@scss/variables" as *;

$this: ".Header";

#{$this} {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
  z-index: 1;
}
