@use "@scss/variables" as *;

$this: ".ModalContent";

#{$this} {

  &--Scrollable {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
  }
}
