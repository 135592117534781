@use "@scss/variables" as *;

$this: ".ServiceCardModalContent";

#{$this} {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  height: 100%;

  &__TitleSection {
    border-bottom: 1px solid $color-navy;
  }

  &__TopBar {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__BackButton {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 26px;
    background-color: #d5c09f;
    border-radius: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &__ContentContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 20px;

    @media (min-width: $breakpoint-medium) {
      flex-direction: row;
      gap: 50px;
      margin-bottom: 50px;
    }

  }

  &__Image {
    height: 300px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    //min-width: 370px;

    @media (min-width: $breakpoint-medium) {
      height: auto;
      min-width: 300px;
    }

    @media (min-width: $breakpoint-large) {
      //height: 450px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: $color-green;
      opacity: 0.6;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $breakpoint-medium) {
      justify-content: flex-start;
    }
  }

  &__Title {
    font-size: 23px;
    color: $color-navy;
    margin: 0;

    @media (min-width: $breakpoint-medium) {
      font-size: 32px;
    }
  }

  &__Description {
    color: $color-navy;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
