@use "@scss/variables" as *;

$this: ".PageContainer";

#{$this} {
  max-width: 1240px;
  margin: 0 auto;
  width: calc(100% - 40px);

  @media (min-width: $breakpoint-medium) {
    width: calc(100% - 80px);
  }
}
