@use "@scss/variables" as *;

$this: ".Logo";

#{$this} {
  display: flex;
  max-width: 250px;
  min-width: 250px;
  width: 250px;

  @media (min-width: $breakpoint-medium) {
    max-width: 300px;
    min-width: 300px;
    width: 300px;
  }

  img {
    max-width: 100%;
  }
}
