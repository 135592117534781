@use "@scss/variables" as *;

$this: ".SectionHeader";

#{$this} {
  font-size: 42px;
  text-align: center;
  margin: 0;
  padding-bottom: 35px;

  @media (min-width: $breakpoint-medium) {
    font-size: 54px;
    padding-bottom: 50px;
  }

  @media (min-width: $breakpoint-large) {
    padding-bottom: 70px;
  }
}
