@use "@scss/variables" as *;

$this: ".Button";

#{$this} {
  border-radius: 30px;
  outline: none;
  box-shadow: none;
  border: none;
  font-family: $font-primary;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  line-height: 1;
  transform: scale(1) translateZ(0);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  outline: 0;

  &:hover {
    transform: scale(1.1) translateZ(0);
  }

  &--green {
    background-color: $color-green;
    color: $color-beige;
  }

  &--beige {
    background-color: $color-beige;
    color: $color-navy;
  }

  &--navy {
    background-color: $color-navy;
    color: $color-beige;
  }

  &--Small {
    padding: 8px 16px;
    font-size: 12px;
    height: 12px;
  }

  &--Medium {
    padding: 12px 24px;
    font-size: 16px;
    height: 16px;
  }

  &--Large {
    padding: 16px 32px;
    font-size: 20px;
    height: 20px;
  }
}
