@use "@scss/variables" as *;

$this: ".ProfileCard";

#{$this} {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  @media (min-width: $breakpoint-medium) {
    flex-direction: row-reverse;
    gap: 50px;
  }

  &__Image {
    width: 250px;
    height: 250px;
    min-width: 250px;
    min-height: 250px;
    border-radius: 6px;
    float: left;
  }

  &__Content {
    margin-bottom: 0;

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__Details {

  }

  &__Title {
    font-size: 26px;

    @media (min-width: $breakpoint-medium) {
      font-size: 30px;
    }
  }

  &__Subtitle {
    font-size: 20px;
    margin-bottom: 25px;

    @media (min-width: $breakpoint-medium) {
      font-size: 24px;
    }
  }
}
