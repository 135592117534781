@use "@scss/variables" as *;

$this: ".Section";

#{$this} {
  position: relative;
  padding: 70px 0;

  @media (min-width: $breakpoint-medium) {
    padding: 100px 0 120px 0;
  }

  &--green {
    background-color: $color-green;
    color: $color-beige;
  }

  &--beige {
    background-color: $color-beige;
    color: $color-navy;
  }

  &--navy {
    background-color: $color-navy;
    color: $color-beige;
  }
}
