@use "@scss/variables" as *;

$this: ".Modal";

#{$this} {
  $transition-duration: 0.2s;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-navy;
    opacity: 0;
    transition-property: opacity;
    transition-duration: $transition-duration;
    transition-timing-function: ease-in-out;
  }

  &__Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -47%) scale(0.97);
    border-radius: 6px;
    background-color: $color-beige;
    color: $color-navy;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform-origin: 50% 50%;
    width: calc(100% - 40px);
    outline: none;
    max-height: calc(100vh - 40px);
    transition: $transition-duration opacity ease-in-out;
    transition-property: opacity, transform;
  }

  &--Visible {
    pointer-events: auto;

    #{$this}__Overlay {
      opacity: 0.6;
    }

    #{$this}__Container {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
