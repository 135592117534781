@use "@scss/variables" as *;

$this: ".ContactCard";

#{$this} {
  display: flex;
  align-items: center;
  gap: 20px;

  &__IconContainer {
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: $color-green;
    border-radius: 6px;
    padding: 12px;
    font-size: 24px;
    color: $color-beige;
  }

  &__IconContainer {
    font-size: 28px;
  }

  &__TitleContainer {
    font-size: 22px;

    @media (min-width: $breakpoint-medium) {
      font-size: 26px;
    }
  }
}
