
$color-green: #204D55;
$color-beige: #D5BF9F;
$color-navy: #182A3A;

$font-primary: "Outfit", sans-serif;
$font-secondary: "Montserrat", sans-serif;
$font-color: #F6F6F4;

$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1200px;
$breakpoint-xxlarge: 1400px;
